import React from 'react';

const SideBarTestimonialContainer = ({ quote }) => (
  <div className="pull-quote">
    <div
      className="pull-quote-wrapper"
      dangerouslySetInnerHTML={{
        __html: quote.body,
      }}
    />
    <div>
      <p className="author-name">{quote.person?.firstName} {quote.person?.lastName}</p>
      <p className="author-role">
        {quote.person?.role},&nbsp;
        {quote.person?.company.name}
      </p>
    </div>
  </div>
);

export default SideBarTestimonialContainer;
